import { API } from "aws-amplify";
const TELEGRAM = window.Telegram.WebApp;

export default function telegramService() {
  const webAppExpand = () => {
    TELEGRAM.expand();
  };

  const mainBtnClick = (mainBtnClickHandler) => {
    TELEGRAM.MainButton.onClick(mainBtnClickHandler);
  };

  const setMainBtnText = (text) => {
    TELEGRAM.MainButton.text = text;
  };

  const mainBtnShow = () => {
    TELEGRAM.MainButton.show();
  };

  const mainBtnDisable = () => {
    TELEGRAM.MainButton.disable();
  }

  const mainBtnEnable = () => {
    TELEGRAM.MainButton.enable();
  }

  const mainBtnHide = () => {
    TELEGRAM.MainButton.hide();
  };
  const mainBtnShowProgress = () => {
    TELEGRAM.MainButton.showProgress();
  };

  const mainBtnHideProgress = () => {
    TELEGRAM.MainButton.hideProgress();
  };

  const backBtnClick = (telegramBackBtnHandler) => {
    TELEGRAM.BackButton.onClick(telegramBackBtnHandler);
  };

  const backBtnShow = () => {
    TELEGRAM.BackButton.show();
  };

  const backBtnHide = () => {
    TELEGRAM.BackButton.hide();
  };

  const createInvoiceLink = async (params) => {
    return await API.get("telegramAPI", "/telegram/createInvoiceLink", params);
  };

  const openInvoice = (params, closeHandler) => {
    TELEGRAM.openInvoice(params, closeHandler);
  };

  const closeWebApp = () => {
    TELEGRAM.close();
  };

  return {
    createInvoiceLink,
    openInvoice,
    backBtnClick,
    backBtnHide,
    backBtnShow,
    webAppExpand,
    mainBtnClick,
    setMainBtnText,
    mainBtnShow,
    mainBtnHide,
    mainBtnDisable,
    mainBtnEnable,
    closeWebApp,
    mainBtnShowProgress,
    mainBtnHideProgress,
  };
}
